import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Professional Services">
    <section>
      Quantic Professional Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Professional Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies. Current practices are benchmarked against industry best practices to identify performance bottlenecks and opportunities for data storage, data backup, and disaster recovery improvement.
      <br/>
      <br/>
      Assigned consultant will determine the best storage and data protection solution to meets your exclusive requirements. He or she will recommend disaster recovery strategies to manage any discovered vulnerabilities and also provide recommendations for the most efficient use of your resources.
      <br/>
      <br/>

      Following your environment initial analysis, consultant will fully integrate the recommended solutions, including the development of policies and procedures, the installation of new software, and any necessary upgrades and migrations.
      <br/>
      <br/>
    </section>
  </Layout>
);

export default Page;
